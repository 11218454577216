
import { mdiAccessPoint,mdiAlertCircleOutline, mdiCheckCircleOutline, mdiTruckFastOutline } from '@mdi/js'
import { Lista as ListaType } from '../../services/GraphQl/types/allDriverData'

export const initialState = {
    drivers: [],
    infoBoxes: []
}

export interface State {
    drivers: ListaType[]
    infoBoxes: any[]
}

export function reducer(state: State, action: any): State {
    switch (action.type) {
        case 'SET_DATA':
            return {
                ...state,
                drivers: action.payload.driver,
                infoBoxes: [
                    { id: 0, title: 'Total', icon: mdiAccessPoint, theme: 'warning', value: action.payload.infoBoxes.total },
                    { id: 2, title: 'Em Aberto', icon: mdiTruckFastOutline, theme: 'info', value: `${action.payload.infoBoxes.percentAberto}% (${action.payload.infoBoxes.totalAberto})` },
                    { id: 3, title: 'Sucesso', icon: mdiCheckCircleOutline, theme: 'success', value: `${action.payload.infoBoxes.percentSucesso}% (${action.payload.infoBoxes.totalSucesso})` },
                    { id: 1, title: 'Insucesso', icon: mdiAlertCircleOutline, theme: 'error', value: `${action.payload.infoBoxes.percentInsucesso}% (${action.payload.infoBoxes.totalInsucesso})` },
                ]
            }
        default:
            return state
    }
}