import {  mdiAccessPoint, mdiAlertCircleOutline, mdiCheckCircleOutline, mdiTruckFastOutline } from '@mdi/js'
import { Driver } from '../../services/GraphQl/types/allDriverData'

export interface State {
    drivers: Driver[],
    date: Date,
    infoBoxes: InfoBox[],
    loading: boolean,
    error: boolean,
    calendar: boolean,
}

export interface Action {
    type: 'SET_DRIVERS' | 'SET_DATE' | 'SET_LOADING' | 'SET_ERROR' | 'SET_CALENDAR',
    payload?: any,
}

export const initialState: State = {
    drivers: [],
    date: new Date(),
    infoBoxes: [],
    loading: false,
    error: false,
    calendar: false,
}

type InfoBox = {
    id: number;
    title: string;
    icon: string;
    theme: string;
    value: string;
}

export function reducer(state: State, action: Action): State {

    switch (action.type) {
        case 'SET_DRIVERS': {
            return {
                ...state,
                drivers: action.payload.drivers,
                loading: false,
                error: false,
                infoBoxes: [
                    { id: 0, title: 'Total', icon: mdiAccessPoint, theme: 'warning', value: action.payload.infoBoxes.total },
                    { id: 2, title: 'Em Aberto', icon: mdiTruckFastOutline, theme: 'info', value: `${action.payload.infoBoxes.percentAberto}% (${action.payload.infoBoxes.totalAberto})` },
                    { id: 3, title: 'Sucesso', icon: mdiCheckCircleOutline, theme: 'success', value: `${action.payload.infoBoxes.percentSucesso}% (${action.payload.infoBoxes.totalSucesso})` },
                    { id: 1, title: 'Insucesso', icon: mdiAlertCircleOutline, theme: 'error', value: `${action.payload.infoBoxes.percentInsucesso}% (${action.payload.infoBoxes.totalInsucesso})` },
                ],
            }
        }
        case 'SET_DATE': {
            return {...state, date: action.payload}
        }
        case 'SET_LOADING': {
            return {...state, loading: !state.loading, error: false}
        }
        case 'SET_ERROR': {
            return {...state, loading: false, error: true}
        }
        case 'SET_CALENDAR': {
            return {...state, calendar: action.payload}
        }
        default: return state
    }

}