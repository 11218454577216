import { Lista } from "../types/allDriverData"

export const getDestinatarioById = async (idDestinatario:number) => {

    if(sessionStorage.getItem("listas")){
        const data:{driver: Lista[]} = JSON.parse(sessionStorage.getItem("listas")!)
        /*
        console.log('🚀 -------------------------------------------------------------------------------------')
        console.log('🚀 - file: destinatariosControllers.ts - line 7 - getDestinatarioById - listas', data)
        console.log('🚀 -------------------------------------------------------------------------------------')
        */
        let destinatarios = data.driver.map(lista => lista.destinatarios)
        /*
        console.log('🚀 ----------------------------------------------------------------------------------------------------')
        console.log('🚀 - file: destinatariosControllers.ts - line 12 - getDestinatarioById - destinatarios', destinatarios)
        console.log('🚀 ----------------------------------------------------------------------------------------------------')
        */
        let destinatario = destinatarios.map(destinatario => destinatario.filter(destinatario => destinatario.idDestinatario === idDestinatario)).flat(1)[0]

        return destinatario
    }


    return undefined
}