import haversine from 'haversine-distance'

type Coordinate = {lat:number, lng:number}

export function calculateDistanceBetweenCoordinates(coordinate1:Coordinate, coordinate2:Coordinate) {

    let distance = haversine(coordinate1, coordinate2)

    return distance > 1000 ? `${Math.trunc(distance/1000)} km` : `${Math.trunc(distance)} m`
    
}