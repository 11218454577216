import React from "react"
import { mdiLightbulbOutline, mdiMonitor, mdiTruckOutline, mdiBrightness4, mdiBrightness5 } from '@mdi/js'

import { useTheme, Theme } from "../../context/ThemeContext"

import './styles.scss'

import Banner from "./Banner"
import IconContainer from "./Icon"

const Header: React.FC = () => {

    const { theme, setTheme } = useTheme()

    return(
        
        <header id = "header">
            <nav>
                <ul id = "headerContainer">
                    <Banner />
                    <li id = "headerBoxGroup" className = "headerContent">
                        <IconContainer title = "Opção" icon = {mdiLightbulbOutline} />
                        <IconContainer title = "Opção" icon = {mdiMonitor} />
                        <IconContainer title = "Opção" icon = {mdiTruckOutline} />
                        <IconContainer
                            title = {theme === 'dark-mode' ? 'Tema claro' : 'Tema escuro'}
                            icon = {theme === 'dark-mode' ? mdiBrightness5 : mdiBrightness4}
                            func = {() => setTheme(theme === 'dark-mode' ? Theme.Light : Theme.Dark)}
                        />
                    </li>
                </ul>
            </nav>
        </header>

    )

}

export default Header