import React from "react"
import { Tooltip } from '@material-ui/core'
import Icon from '@mdi/react'

import { IconProps } from "./types"

const IconContainer: React.FC <IconProps> = props => {

    return(
        
        <>
        
            <Tooltip title = {props.title} arrow>
                <div className = "headerBox" onClick = {() => props.func ? props.func() : null}>
                    <Icon path = {props.icon} size = "24px" />
                </div>
            </Tooltip>
        
        </>

    )

}

export default IconContainer