import React from 'react'
import Icon from '@mdi/react'

import * as Types from '../types'

export const DestinatarioInfoGroup: React.FC <Types.DestinatarioInfoGroupProps> = props => {

    return <div className = "destinatarioInfoGroup" style = {{gridTemplateColumns: `repeat(${props.columns}, 1fr)`}}> {props.children} </div>

}

const DestinatarioInfo: React.FC <Types.DestinatarioInfoProps> = props => {

    return(

        <>
        
            <ul className = "destinatarioInfoContainer">

                <li className = "destinatarioInfoContent">
                    <Icon path = {props.icon} size = "32px" />
                </li>
                <li className = "destinatarioInfoContent">
                    <h3> {props.title} </h3> 
                    <h4> {props.text} </h4>
                </li>

            </ul>
        
        </>

    )

}

export default DestinatarioInfo