import { mdiCalendarCheck, mdiCalendarClock, mdiCheckCircle, mdiCity, mdiEarth, mdiHomeCity, mdiInformation, mdiMapMarker, mdiRoad, mdiTruckFast } from '@mdi/js'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import Box from '../../components/Box'
import Section from '../../components/Section'
import { getDestinatarioById } from '../../services/GraphQl/controllers/destinatariosControllers'
import { Destinatario as DestinatarioType } from '../../services/GraphQl/types/allDriverData'
import { calculateDistanceBetweenCoordinates } from '../../utils/Coordinates_utils'
import SetStatusThemeColor from '../../utils/SetStatusThemeColor'
import DestinatarioInfo, { DestinatarioInfoGroup } from './DestinatarioInfo/index'
import './styles.scss'

const Destinatario: React.FC = () => {

    let {idDestinatario} = useParams<{idDestinatario: string}>()
    const [destinatario, setDestinatario] = useState<DestinatarioType>()

    useEffect(() => {
        (async () => {
            const data = await getDestinatarioById(Number(idDestinatario))
            setDestinatario(data) 
        })()
    }, [idDestinatario])

    return(

        <>
            {destinatario &&
                <>

                    <Section type = "default" name = "Destinatario" title = "Destinatário" subTitle = {idDestinatario} goBack>

                        <Box.Group columns = {1}>

                            <Box.Item statusTitle = {destinatario.nome} padding = "24px">
                                <Box.Section>
                                    <DestinatarioInfoGroup columns = {3}>
                                        <DestinatarioInfo icon = {mdiRoad} title = "CEP" text = {destinatario.cep} />
                                        <DestinatarioInfo icon = {mdiMapMarker} title = "Endereço" text = {`${destinatario.endereco}, ${destinatario.numero}`} />
                                        <DestinatarioInfo icon = {mdiEarth} title = "UF" text = {destinatario.uf} />
                                        <DestinatarioInfo icon = {mdiCity} title = "Cidade" text = {destinatario.cidade} />
                                        <DestinatarioInfo icon = {mdiHomeCity} title = "Bairro" text = {destinatario.bairro} />
                                    </DestinatarioInfoGroup>
                                </Box.Section>
                            </Box.Item>
                            
                        </Box.Group>

                    </Section>

                    <Section type = "default" name = "Notas" title = "Notas">

                        <Box.Group columns = {2}>
                            
                            {destinatario.notas.map(nota => (
                                <Box.Item key = {nota.idNota} statusTitle = {`${nota.nrNota}`} theme = {`status ${SetStatusThemeColor(nota.situacao)}`} padding = "24px">
                                    <Box.Section>
                                        <DestinatarioInfoGroup columns = {2}>
                                            <DestinatarioInfo icon = {nota.situacao === 'Em aberto' ? mdiTruckFast : nota.situacao === 'Ocorrencia' ? mdiInformation : mdiCheckCircle} title = "Situação" text = {nota.situacao === 'Ocorrencia' ? `${nota.notaOcorrencias[0].descricaoTipoOcorrencia}` : `${nota.situacao}`} />
                                            <DestinatarioInfo icon = {mdiCalendarClock} title = "Previsão" text = { !!nota.previsaoEntrega ? (new Date(nota.previsaoEntrega!)).toLocaleDateString() : 'Sem previsão de entrega'} />
                                            {(nota.situacao === 'Sucesso' && 
                                                <>
                                                    <DestinatarioInfo icon = {mdiCalendarCheck} title = "Data da baixa" text = { !!nota.baixaDtEntrega ? (new Date(nota.baixaDtEntrega!)).toLocaleString() : 'Sem data de baixa'} />
                                                    <DestinatarioInfo
                                                        icon = {mdiRoad}
                                                        title = "Distância" 
                                                        text = {calculateDistanceBetweenCoordinates({
                                                            lat: Number(destinatario.latitude)!,
                                                            lng: Number(destinatario.longitude)!,
                                                        },{
                                                            lat: Number(nota.baixaLatitude),
                                                            lng: Number(nota.baixaLongitude)
                                                        })}
                                                    />
                                                </>
                                            ) || (nota.situacao === 'Ocorrencia' &&
                                                <>
                                                    <DestinatarioInfo icon = {mdiCalendarCheck} title = "Data da baixa" text = { !!nota.notaOcorrencias[0].createdAt ? (new Date(nota.notaOcorrencias[0].createdAt)).toLocaleString(): 'Ainda não baixado'} />
                                                    <DestinatarioInfo 
                                                        icon = {mdiRoad}
                                                        title = "Distância" 
                                                        text = {calculateDistanceBetweenCoordinates({
                                                            lat: Number(destinatario.latitude)!,
                                                            lng: Number(destinatario.longitude)!,
                                                        },{
                                                            lat: Number(nota.notaOcorrencias[0].latitude!),
                                                            lng: Number(nota.notaOcorrencias[0].longitude!)
                                                        })}
                                                    />
                                                </>
                                            )}
                                        </DestinatarioInfoGroup>
                                    </Box.Section>
                                    
                                    {nota.situacao === 'Sucesso' ? (
                                        <>
                                            <Box.Section>
                                                <Box.LinkLabel link = {`https://www.google.com.br/maps/search/${nota.baixaLatitude},${nota.baixaLongitude}/@${nota.baixaLatitude},${nota.baixaLongitude},14z`} label = "Localização da baixa" />
                                            </Box.Section>
                                            {nota.foto ? (
                                                <Box.Section>
                                                    <Box.Img imgUrl = {nota.foto!} />
                                                </Box.Section>
                                            ) : null} 
                                        </>
                                    ) : nota.situacao === 'Ocorrencia' ? (
                                        <>
                                            <Box.Section>
                                                <Box.LinkLabel link = {`https://www.google.com.br/maps/search/${nota.notaOcorrencias[0].latitude!},${nota.notaOcorrencias[0].longitude!}/@${nota.notaOcorrencias[0].latitude!},${nota.notaOcorrencias[0].longitude!},14z`} label = "Localização da baixa" />
                                            </Box.Section>
                                            {nota.notaOcorrencias[0].foto ? (
                                                <Box.Section>
                                                    <Box.Img imgUrl = {nota.notaOcorrencias[0].foto} />
                                                </Box.Section>
                                            ) : null}
                                        </>
                                    ) : null} 
                                    
                                </Box.Item>
                            ))}

                        </Box.Group>

                    </Section>

                </>
            }
        </>

    )

}

export default Destinatario