import React from 'react'

import { RowProps } from '../types'

const Row:React.FC <RowProps> = ({ children, theme, color }) => {

    return(

        <li className = "listRow">
            {(theme && !color && <ul className = {`listRowContainer ${theme}`}> {children} </ul>) || <ul className = "listRowContainer" style = {{color: color}}> {children} </ul>}
        </li>

    )

}

export default Row