import React from 'react'
import { Link } from 'react-router-dom'
import { Tooltip } from '@material-ui/core'
//import { LocalShippingOutlined, EmojiObjectsOutlined, DesktopWindowsOutlined } from '@material-ui/icons'

const Banner: React.FC = () => {

    return(

        <li id = "headerBanner" className = "headerContent">
                
            <Tooltip title = "Home" arrow>
                <Link to = '/'>
                    <span id = "headerBannerText"> UX </span>
                </Link>
            </Tooltip>  
            <span id = "headerBannerTextComplement"> Painel de Controle </span>

        </li>

    )

}

export default Banner