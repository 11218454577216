import { Driver, Lista } from "../types/allDriverData";

export const getAllDriver = async (date: string) => {
    const response = await fetch(`http://truxapimobile.uxdelivery.com.br/Api/Motoristas/MotoristasResumo/?data=${date}`)
    const data = await response.json()

    const drivers = data.listaResultados as Driver[]

    if(drivers.length > 0){
        const totalAberto = drivers.map(driver => driver.totalEmAberto).reduce((acc, current) => acc + current)
        const totalInsucesso = drivers.map(driver => driver.totalInsucesso).reduce((acc, current) => acc + current)
        const totalSucesso = drivers.map(driver => driver.totalSucesso).reduce((acc, current) => acc + current)
        const total = totalAberto + totalInsucesso + totalSucesso

        const percentAberto = ((totalAberto * 100) / total).toFixed(1)
        const percentInsucesso = ((totalInsucesso * 100) / total).toFixed(1)
        const percentSucesso = ((totalSucesso * 100) / total).toFixed(1)

        return {drivers: data.listaResultados, infoBoxes: {total, totalAberto, percentAberto, totalInsucesso, percentInsucesso, totalSucesso, percentSucesso}}
    }
    return null
    
}

export const getAllDataFromDriver = async (idMotorista: number, flagNovo: boolean, date: string) => {

    let response = await fetch(`http://truxapimobile.uxdelivery.com.br/Api/Listas/ListaSolicitacao/?idMotorista=${idMotorista}&flagNovo=${flagNovo}&data=${date}`)
    let data: Lista[] = await response.json()

    const totalAberto = data[0].destinatarios.filter(dest => dest.notas.filter(note => note.situacao === 'Em aberto').length > 0).length
    const totalInsucesso = data[0].destinatarios.filter(dest => dest.notas.filter(note => note.situacao === 'Ocorrencia').length > 0).length
    const totalSucesso = data[0].destinatarios.filter(dest => dest.notas.filter(note => note.situacao === 'Sucesso').length > 0).length

    const total = totalAberto + totalInsucesso + totalSucesso

    const percentAberto = ((totalAberto * 100) / total).toFixed(1)
    const percentInsucesso = ((totalInsucesso * 100) / total).toFixed(1)
    const percentSucesso = ((totalSucesso * 100) / total).toFixed(1)

    return {driver: data, infoBoxes: {total, totalAberto, percentAberto, totalInsucesso, percentInsucesso, totalSucesso, percentSucesso}}

}