import React from 'react'
import { Icon as MdiIcon } from '@mdi/react'

import { ColumnProps } from '../types'
import { Tooltip } from '@material-ui/core'

const Column:React.FC <ColumnProps> = ({ type, theme, center, color, text, icon, iconSize, iconTooltip }) => {

    return(

        <>

            {type === 'icon' ? (
                <li className = {`listColumn icon ${theme}`} style = {{justifyContent: center ? 'center' : ''}}>
                    {iconTooltip && icon!== '' ? 
                        <Tooltip title = {iconTooltip} placement = "top" arrow>
                            <MdiIcon path = {icon} size = {`${iconSize}px`} />
                        </Tooltip> 
                    : <MdiIcon path = {icon} size = {`${iconSize}px`} />}
                </li>
            ) : (
                color ? (
                    <li className = "listColumn" style = {{justifyContent: center ? 'center' : '', color: color}}> {text} </li>
                ) : (   
                    <li className = {`listColumn ${theme}`} style = {{justifyContent: center ? 'center' : ''}}> {text} </li>
                )
            )}
        
        </>

    )

}

export default Column