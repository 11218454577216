import { mdiAlertCircleOutline, mdiCalendarSearch, mdiCellphoneInformation, mdiCheckCircleOutline, mdiCheckDecagram, mdiClipboardListOutline, mdiMapMarkerCircle, mdiNumeric, mdiTruckCheck, mdiTruckDelivery, mdiTruckFastOutline } from '@mdi/js'
import React, { useEffect, useReducer, useRef } from 'react'
import { Link } from 'react-router-dom'
import Box from '../../components/Box'
import List from '../../components/List'
import Section from '../../components/Section'
import { getAllDriver } from '../../services/GraphQl/controllers/getDriverDataController'
import { initialState, reducer } from './homeReducer'
import './styles.scss'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'
import { format } from "date-fns";
import FAB from '../../components/FAB'

const Home: React.FC = () => {
    
    const calendarRef = useRef<HTMLDivElement>(null)
    const fabRef = useRef<HTMLButtonElement>(null)

    const [state, dispatch] = useReducer(reducer, initialState)
    const formattedDate = format(state.date, "dd-MM-yyyy")

    const calendarHandleClickOutside = (e: any) => {
        if(calendarRef.current?.contains(e.target) || e.target === calendarRef.current || e.target === fabRef.current) return
        dispatch({type: 'SET_CALENDAR', payload: false})
    }
    
    useEffect(() => {
        state.calendar ? document.addEventListener("mousedown", calendarHandleClickOutside) : document.removeEventListener("mousedown", calendarHandleClickOutside)
        return () => document.removeEventListener("mousedown", calendarHandleClickOutside)
    }, [state.calendar])

    useEffect(() => {
        (async () => {
            dispatch({type: 'SET_LOADING'})
            const response = await getAllDriver(state.date.toLocaleDateString())
            if(response){
                dispatch({type: 'SET_DRIVERS', payload: response}) 
            }else{
                dispatch({type: 'SET_ERROR'})
            }
        })()
    }, [state.date])

    try {

        const SHOW_DRIVERS = state.drivers.length > 0 && !state.loading && !state.error

        return (
    
            <>
    
                <Section type="default" name="Resume" title="Acompanhamento dos Motoristas" subTitle = {`Motoristas - ${state.date.toLocaleDateString()}`}>
                    <Box.Group columns={4}>
                        {SHOW_DRIVERS && state.infoBoxes.map(resume => (
                            <Box.Item key={resume.id} statusTitle={resume.title} theme={`status ${resume.theme}`} padding="32px 24px">
                                <Box.Section>
                                    <Box.Icon icon={resume.icon} title={resume.value} size="32px" />
                                </Box.Section>
                            </Box.Item>
                        ))}
                    </Box.Group>
                </Section>
                
                <Section type="default" name="Home">
                    <List.Group id="Home">
                        <List.Section type="header">
                            <List.Row color="#fff">
                                <List.Column type="default" text="ID" />
                                <List.Column type="default" text="Nome" />
                                <List.Column type="icon" icon={mdiClipboardListOutline} iconSize = {24} iconTooltip = "Listas" center />
                                <List.Column type="icon" icon={mdiNumeric} iconSize = {24} iconTooltip = "Solicitações" center />
                                <List.Column type="icon" icon={mdiTruckFastOutline} iconSize = {24} iconTooltip = "Em rota" center />
                                <List.Column type="icon" icon={mdiMapMarkerCircle} iconSize = {24} iconTooltip = "Total em abertos" center />
                                <List.Column type="icon" icon={mdiCheckCircleOutline} iconSize = {24} iconTooltip = "Total de sucessos" center />
                                <List.Column type="icon" icon={mdiAlertCircleOutline} iconSize = {24} iconTooltip = "Total de insucessos" center />
                                <List.Column type="icon" icon={mdiCellphoneInformation} iconSize = {24} iconTooltip = "App novo" center />
                                <List.Column type="default" text = "Versão" />
                            </List.Row>
                        </List.Section>

                        <List.Section type="main">
                            {SHOW_DRIVERS && state.drivers.map(driver => (
                                <Link key={driver.id} to={`/listas/${driver.id}/${driver.nome}/${driver.flagNovoApp? '1' : '0'}/${formattedDate}/${driver.uuidRastreio ? driver.uuidRastreio : 'semRastreio'}`}>
                                    <List.Row >
                                        <List.Column type="default" text={driver.id.toString()} />
                                        <List.Column type="default" text={driver.nome} />
                                        <List.Column type="default" text={driver.totalListas.toString()} center />
                                        <List.Column type="default" text={driver.totalSolicitacoes.toString()} center />
                                        <List.Column type="icon" icon = {driver.flagAcaminho ? mdiTruckDelivery : ''} theme = {driver.flagAcaminho ? 'status warning' : ''} iconSize = {20} center />
                                        <List.Column type="default" text={driver.totalEmAberto.toString()} theme="status info" center />
                                        <List.Column type="default" text={driver.totalSucesso.toString()} theme="status success" center />
                                        <List.Column type="default" text={driver.totalInsucesso.toString()} theme="status error" center />
                                        <List.Column type="icon" icon={ driver.flagNovoApp? mdiCheckDecagram : ''} iconSize = {20} theme="status success" center />
                                        <List.Column type="default" text={driver.versao} />
                                    </List.Row>
                                </Link>
                            ))}
                            {state.loading && <List.Status />}
                        </List.Section>
                    </List.Group>
                </Section>

                <FAB.Group> 
                    <FAB.Item fabRef = {fabRef} tooltip = "Consultar data" icon = {mdiCalendarSearch} func = {() => dispatch({type: 'SET_CALENDAR', payload: !state.calendar})} />
                </FAB.Group>

                <div id = "calendarContainer" className = {state.calendar ? 'open' : ''}>
                    <div ref = {calendarRef} id = "calendarContent">
                        <Calendar
                            locale = "pt-br"
                            onChange = {(value: Date) => {
                                dispatch({type: 'SET_DATE', payload: value})
                                dispatch({type: 'SET_CALENDAR', payload: false})
                            }}                              
                            value = {state.date} 
                        />
                    </div>
                </div>
            </>
        )
        
    } catch (error) {
        return <h1>Erro</h1>
    }


}

export default Home