import React from 'react'

import Routes from './routes'

import './styles/base/App.scss'
import './styles/base/Layout.scss'

const App: React.FC = () => {

    return <Routes />

}

export default App