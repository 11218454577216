import React, { Fragment, useEffect, useReducer } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { determinaSituacao } from '../../components/Destinatario/determinaSituacao'
import List from '../../components/List'
import Section from '../../components/Section'
import { getAllDataFromDriver } from '../../services/GraphQl/controllers/getDriverDataController'
import { calculateDistanceBetweenCoordinates } from '../../utils/Coordinates_utils'
import './styles.scss'
import Box from '../../components/Box'
import { reducer, initialState } from './listasReducer'
import FAB from '../../components/FAB'
import { mdiCrosshairsGps } from '@mdi/js'

const Listas: React.FC = () => {

    const [state, dispatch] = useReducer(reducer, initialState)
    let { idMotorista, name, appNovo, date, uuidRastreio } = useParams<{ idMotorista: string, name: string, appNovo: string, date: string, uuidRastreio:string }>()

    const dateFormat = date.replace(/-/g, '/')
    
    useEffect(() => {
        (async () => {
            const data = await getAllDataFromDriver(Number(idMotorista), appNovo === '0' ? false : true, dateFormat)
            sessionStorage.setItem('listas', JSON.stringify(data))
            dispatch({ type: 'SET_DATA', payload: data })
        })()
    }, [idMotorista, appNovo, dateFormat])

    return (

        <>
            <Section type="default" name="Resume" title={name} subTitle={`Listas - ${dateFormat}`} goBack>
                <Box.Group columns={4}>
                    {state.infoBoxes.map(resume => (
                        <Box.Item key={resume.id} statusTitle={resume.title} theme={`status ${resume.theme}`} padding="32px 24px">
                            <Box.Section>
                                <Box.Icon icon={resume.icon} title={resume.value} size="32px" />
                            </Box.Section>
                        </Box.Item>
                    ))}
                </Box.Group>
            </Section>

            <Section type="default" name="Listas">

                <List.Group id="Listas">

                    <List.Section type="header">
                        <List.Row color="#fff">
                            <List.Column type="default" text="Destinatário" />
                                {appNovo === '1' && <List.Column type="default" text="Distância da Baixa" />}
                            <List.Column type="default" text="Notas" />
                        </List.Row>
                    </List.Section>

                    <List.Section type="main">
                        {(state.drivers.length > 0 && state.drivers.map(lista => lista.destinatarios.map(destinatario => {
                            let situacao = determinaSituacao(destinatario)
                            const theme = situacao === 'Finalizado' ? 'success' : situacao === 'Não Finalizado' ? 'info' : 'error'
                            const APP_NOVO = appNovo === '1'
                            const SHOW_LINK = APP_NOVO

                            const content = (
                                <List.Row theme={`status ${theme}`}>
                                    <List.Column type="default" text={destinatario.nome} />
                                    {APP_NOVO && <List.Column type="default" text={situacao === 'Finalizado' ? calculateDistanceBetweenCoordinates({
                                        lat: Number(destinatario.latitude)!,
                                        lng: Number(destinatario.longitude)!,
                                    }, {
                                        lat: Number(destinatario.notas[0].baixaLatitude),
                                        lng: Number(destinatario.notas[0].baixaLongitude)
                                    }) : situacao === 'Ocorrencia' ? calculateDistanceBetweenCoordinates({
                                        lat: Number(destinatario.latitude)!,
                                        lng: Number(destinatario.longitude)!,
                                    }, {
                                        lat: Number(destinatario.notas[0].baixaLatitude!),
                                        lng: Number(destinatario.notas[0].baixaLongitude!)
                                    }) : 'Não baixado'} />}
                                    <List.Column type="default" text={destinatario.notas.length.toString()} />
                                </List.Row>
                            )

                            return (
                                <Fragment key={destinatario.idDestinatario}>
                                    {SHOW_LINK && <Link to={`/destinatario/${destinatario.idDestinatario}`}>{content}</Link>}
                                    {!SHOW_LINK && content}
                                </Fragment>
                            )
                        }))) || <List.Status />}

                    </List.Section>
                </List.Group>

            </Section>

            {uuidRastreio !== 'semRastreio' &&
                <FAB.Group> 
                    <FAB.Item tooltip = "Localizar motorista" icon = {mdiCrosshairsGps} link = {`http://rastreio.trux.uxsolutions.com.br/${uuidRastreio}`} blank />
                </FAB.Group>
            }

        </>
    )
}

export default Listas;