import React, { useState } from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"

import { Theme, ThemeContext } from '../context/ThemeContext'

import PageRender from '../components/PageRender'

import Home from '../pages/Home'
import Listas from '../pages/Listas'
import Destinatario from '../pages/Destinatario'

const Routes: React.FC = () => {

    const [storage] = useState(localStorage.getItem('theme'))
    const [theme, setTheme] = useState(storage === 'dark-mode' ? Theme.Dark : Theme.Light)

    return(

        <ThemeContext.Provider value = {{ theme, setTheme }}>
            <Router>
                <Switch>

                    <Route
                        exact path = "/"
                        render = {props => (
                            <PageRender {...props} pageType = "default" name = "Home" title = "Home" theme1 = {theme} component = {<Home />} />
                        )}
                    />

                    <Route
                        exact path = "/listas/:idMotorista/:name/:appNovo/:date/:uuidRastreio"
                        render = {props => (
                            <PageRender {...props} pageType = "default" name = "Listas" title = "Listas" theme1 = {theme} component = {<Listas />} />
                        )}
                    />

                    <Route
                        exact path = "/destinatario/:idDestinatario"
                        render = {props => (
                            <PageRender {...props} pageType = "default" name = "Destinatario" title = "Destinatário" theme1 = {theme} component = {<Destinatario />} />
                        )}
                    />

                </Switch>
            </Router>
        </ThemeContext.Provider>

    )

}

export default Routes