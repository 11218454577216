import React, { useEffect } from 'react'

import './styles.scss'
import { PageRenderProps } from './types'

import ScrollReset from '../../utils/ScrollReset'

import Header from '../Header'
import Main from '../Main' 

const PageRender: React.FC <PageRenderProps> = ({ title, theme1, name, component }) => {

    useEffect(() => {
        document.title = title
    }, [title])

    return(
        
        <div id = "wrapper" className = {`wrapperDefault ${theme1}`} data-theme = {theme1} data-page = {name}>
            <ScrollReset />
            <Header />
            <Main name = {name}> {component} </Main>
        </div>

    )

}

export default PageRender